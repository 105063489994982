<template>
  <div class="view pa24">
    <div class="d-flex">
      <div>
        <el-input
            style="width: 200px!important;"
            class="w120 mb10 mr10"
            placeholder="页面名称"
            v-model="filterName"
            clearable
        />
        <el-button type="primary" @click="filterNames">搜索</el-button>
      </div>
      <div style="margin-left: auto">
        <el-button class="ma" type="primary" @click="add"
        >+ 新增页面</el-button
        >
      </div>
    </div>
    <commonTable
        :tableData="tableData"
        @handleSelectionChange="handleSelectionChange"
        :total="total"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
        :currentPage="currentPage"
        :loading="loading"
    >
      <template v-slot:table>
        <el-table-column type="selection" />
        <el-table-column prop="pageName" align="center" label="页面名称" />
        <el-table-column prop="typeState" align="center" label="页面类型" />
        <el-table-column prop="createTime" align="center" label="创建时间" />
        <el-table-column align="center" label="操作" width="300px">
          <template slot-scope="scope">
            <el-button type="primary" plain @click="edit(scope.row.dataId)"
            >编辑</el-button
            >
            <el-button type="info" style="background: rgba(194, 231, 176, 0.1);color: rgba(103, 194, 58, 1);border-color:#c2e7b0" plain @click="step(scope.row.dataId)"
            >页面设置</el-button
            >
            <customPopconfirm
                class="ma ml10 mb10"
                confirm-button-text="确定"
                cancel-button-text="取消"
                @confirm="del(scope.row.dataId)"
                title="确认删除？">
              <el-button type="danger" slot="reference" plain>删除</el-button>
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import customPopconfirm from "@/components/common/customPopconfirm";
import {queryCustomPage,delCustomPageById} from "@/api/content";
export default {
  name: "customPage",
  components: {
    commonTable,
    customPopconfirm
  },
  data() {
    return {
      tableData: [],
      filterName: "",
      loading:false,
      total: 0, //总条数
      pageNum: 1,
      pageSize: 10,
      currentPage:1,
    };
  },
  mounted(){

    this.queryPage();
  },
  methods: {
    async queryPage(payload) {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        associationId: localStorage.getItem("associationId"),
        ...payload
      };
      try {
        this.loading = true;
        const result = await queryCustomPage(data);
        this.loading = false;
        const { total, list } = result.data.pageInfo;
        let a = []
        for (let i in list) {
          list[i].createTime = this.renderTime(list[i].createTime)
          if (list[i].typeState == 1) {
            list[i].typeState = '商品'
          }else if (list[i].typeState == 2) {
            list[i].typeState = '文章'
          }
        }
        this.srcList = a
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.tableData = []
        this.loading = false;
      }
    },
    renderTime(date) {
      var dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    },
    handleSelectionChange(value) {
    },
    //搜索
    async filterNames() {
      this.currentPage = 1;
      let data = {
        pageName:this.filterName
      };
      this.queryPage(data);
    },
    //当前页变更
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryPage();
    },
    //当前页查询数据条数变更
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryPage();
    },
    /**@method 删除
     * @param {String} val - 文章id
     */
    async del(val) {
      try {
        await delCustomPageById({ dataId: val });
        await this.queryPage();
      } catch (error) {
        console.log(error);
      }
    },
    async add () {
      this.$router.push('/addCustomPage')
    },
    step(dataId){
      this.$router.push({ path:`/stepCustomPage?dataId=${dataId}` });
    },
    edit(dataId){
      this.$router.push({ path:`/addCustomPage?dataId=${dataId}` });
    }
  },
};
</script>

<style lang="scss" scoped></style>


